import React from "react"

export default function AnalyticsIcons() {
	return (
		<svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2.75 2C2.75 1.58579 2.41421 1.25 2 1.25C1.58579 1.25 1.25 1.58579 1.25 2V12.0574C1.24999 14.3658 1.24998 16.1748 1.43975 17.5863C1.63399 19.031 2.03933 20.1711 2.93414 21.0659C3.82895 21.9607 4.96897 22.366 6.41371 22.5603C7.82519 22.75 9.63423 22.75 11.9426 22.75H22C22.4142 22.75 22.75 22.4142 22.75 22C22.75 21.5858 22.4142 21.25 22 21.25H12C9.62178 21.25 7.91356 21.2484 6.61358 21.0736C5.33517 20.9018 4.56445 20.5749 3.9948 20.0052C3.42514 19.4355 3.09825 18.6648 2.92637 17.3864C2.75159 16.0864 2.75 14.3782 2.75 12V2Z" fill="#1C274C" />
			<path d="M19.5875 7.46641C19.8451 7.14204 19.791 6.67026 19.4666 6.41267C19.1422 6.15508 18.6704 6.20921 18.4128 6.53359L15.2948 10.46C15.0496 10.7688 14.8887 10.9708 14.7561 11.1162C14.6265 11.2585 14.5657 11.2989 14.538 11.3137C14.3272 11.4264 14.0754 11.4319 13.8599 11.3285C13.8316 11.3149 13.7691 11.2772 13.6333 11.1407C13.4946 11.0011 13.3251 10.8063 13.0666 10.5085L13.0505 10.4898C12.8126 10.2157 12.6098 9.98186 12.4308 9.80184C12.2448 9.6147 12.0414 9.4401 11.7894 9.31918C11.143 9.00898 10.3875 9.02541 9.75518 9.36342C9.50872 9.49518 9.31307 9.67845 9.13536 9.87351C8.96441 10.0612 8.77192 10.3036 8.54619 10.5878L5.41267 14.5336C5.15508 14.8579 5.20921 15.3297 5.53358 15.5873C5.85795 15.8449 6.32973 15.7908 6.58733 15.4664L9.70551 11.54C9.95077 11.2311 10.1116 11.0292 10.2442 10.8837C10.3738 10.7414 10.4347 10.7011 10.4623 10.6863C10.6731 10.5736 10.925 10.5681 11.1404 10.6715C11.1687 10.6851 11.2313 10.7228 11.367 10.8593C11.5057 10.9989 11.6752 11.1936 11.9337 11.4915L11.9498 11.5101C12.1877 11.7843 12.3905 12.0181 12.5695 12.1981C12.7555 12.3853 12.9589 12.5599 13.2109 12.6808C13.8573 12.991 14.6129 12.9746 15.2452 12.6365C15.4916 12.5048 15.6873 12.3215 15.865 12.1264C16.0359 11.9388 16.2284 11.6964 16.4541 11.4121L19.5875 7.46641Z" fill="#1C274C" />
		</svg>
	)
}
