import LocalizedStrings from "react-localization"

const localization = new LocalizedStrings({
	US: {
		logout: "Logout",
	},
	RS: {
		logout: "Odjavi se",
	},
})

export default localization
